<template>
    <v-app>
            <v-row>
                <v-col>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">
                                    Program
                                </th>
                                <th class="text-left">
                                    Grade
                                </th>
                                <th class="text-left">
                                    Course
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    v-for="item in assignedTeacherCourseList"
                                    :key="item.name"
                            >
                                <td>{{ item.program_title }}</td>
                                <td>{{ item.grade_title }}</td>
                                <td>{{ item.title }}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
    </v-app>
</template>

<script>
    import UserService from "@/core/services/user/UserService";
    import AcademiClassService from "@/core/services/academic-class/AcademicClassService";
    const userService = new UserService();
    const academiClassService = new AcademiClassService();
    export default {
        name: "teacher-courses",
        data() {
            return {
                assignedTeacherCourseList: [],
            }
        },
        mounted() {
            this.getAssignedTeacherCoursesList();
        },
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
        },
        methods: {
            getAssignedTeacherCoursesList() {
                academiClassService.getAssignedTeacherCoursesList(this.currentUser.id).then(response => {
                    this.assignedTeacherCourseList = response.data;
                }).catch((err) => {
                    // console.log(err)
                }).finally(() => {
                });
            },
        },
    }
</script>

<style scoped>

</style>
