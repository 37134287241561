import { render, staticRenderFns } from "./teacher-courses.vue?vue&type=template&id=6a2459d0&scoped=true"
import script from "./teacher-courses.vue?vue&type=script&lang=js"
export * from "./teacher-courses.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a2459d0",
  null
  
)

export default component.exports